<template>
  <div>
    <v-snackbar v-model="snackbar" left top :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn class="white--text" text v-bind="attrs" @click="closeSnack">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "snackbarComp",
  props: ["snackbar", "color", "text"],

  methods: {
    closeSnack() {
      this.$emit("closeSnack");
    },
  },
};
</script>

<style></style>
