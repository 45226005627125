<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="50%"
      style="box-shadow: unset !important"
    >
      <v-card id="subscribe-dialog" class="reschedule">
        <v-container id="">
          <v-row>
            <v-col cols="12" sm="6" class="left-col d-none d-sm-block pa-0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <div class="slogan">
                      <h2 class="slogan1 white--text">انضم لاكبر مجتمع</h2>
                      <h2 class="slogan2 second-color">تجميل فى العالم</h2>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="6" class="right-col pa-0">
              <v-card-text class="text-center px-4 px-sm-8">
                <div class="content">
                  <p class="text-center error--text" v-if="validationMsg">
                    {{ validationMsg }}
                  </p>
                  <h2 class="text-right mb-4">
                    <v-icon>mdi-calendar-month</v-icon>
                    التاريخ
                  </h2>

                  <v-date-picker
                    v-if="selectedDate"
                    color="#a53860"
                    v-model="selectedDate"
                    :allowed-dates="allowedDates"
                    :min="nowDate"
                  ></v-date-picker>
                  <p class="text-center" v-if="available">{{ available }}</p>
                  <hr class="main-hr" />

                  <h2 class="text-right mb-4">
                    <v-icon>mdi-timer-outline</v-icon>
                    الوقت
                  </h2>
                  <v-row>
                    <v-col>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="selectedTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="selectedTime"
                            label="الزمن"
                            class="rounded-input"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[
                              validationRules.required,
                              validationRules.spaces,
                            ]"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          color="#a53860"
                          v-if="menu2"
                          v-model="selectedTime"
                          full-width
                          @click:minute="$refs.menu2.save(selectedTime)"
                          :allowed-hours="allowedHours"
                          :allowed-minutes="allowedMinutes"
                          :disabled="selectedDate == ''"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="close-action text-center mt-4">
                  <v-btn class="main-btn ml-3 curve-btn" @click="save">
                    حفظ
                  </v-btn>
                  <v-btn class="cancel-btn curve-btn" @click="closeDialog">
                    اغلاق
                  </v-btn>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "rescheduleDialog",
  mixins: [validationMixin],

  props: [
    "reserve_id",
    "dialog",
    "slug",
    "appointmentDate",
    "appointmentHours",
    "appointmentMinutes",
  ],
  computed: {
    ...mapGetters(["base_url"]),
  },
  data() {
    return {
      menu2: false,
      text: "",
      selectedDate: "",
      selectedTime: "",
      nowDate: new Date(Date.now()).toISOString().slice(0, 10),
      weekDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      allowedDays: [],
      allowedDates: (val) =>
        this.allowedDays.includes(this.weekDays[new Date(val).getDay()]),
      allowedHours: (h) => this.hours.includes(h),
      allowedMinutes: (m) => m % 30 === 0,
      hours: [],
      available: "",
      mins: ["00", "30"],
      validationMsg: "",
    };
  },
  watch: {
    selectedDate: {
      handler() {
        this.getAvailableHours();
      },
    },
  },
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },

    getBranchData() {
      this.$http
        .get(
          this.base_url + "/salon/branches/" + this.slug + "/show",

          {}
        )
        .then((response) => {
          console.log(response);
          if (response.data.data == null) {
            this.$router.push({ path: "/404" });
          } else {
            this.allowedDays = response.data.data.allowedDays;
          }
        });
    },
    getAvailableHours() {
      this.$http
        .get(
          this.base_url +
            "/salon/branches/" +
            this.slug +
            "/hours?date=" +
            this.selectedDate
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.hours = response.data.data.hours;
            this.available = response.data.data.available;
          } else {
            //
          }
        });
    },

    save() {
      let timeFormate = this.selectedTime.split(":");
      this.$http
        .post(
          this.base_url +
            "/salon/reservations/rescheduleForweb/" +
            this.reserve_id,
          {
            reservation_date: this.selectedDate,
            hours: timeFormate[0],
            minutes: timeFormate[1],
          },
          {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.text = "تم تأكيد الطلب";
            this.text +=
              " التاريخ: " + this.selectedDate + " الوقت: " + this.selectedTime;
            this.closeDialog();
            this.$emit("updateData");
            this.$emit("showSnackbar", this.text);
          } else {
            this.validationMsg = response.data.status.message;
          }
        });
    },
  },
  mounted() {
    this.selectedDate = this.appointmentDate;
    this.getBranchData();
    this.selectedTime =
      Number(this.appointmentHours) + ":" + this.appointmentMinutes;
    this.selectedMin = this.appointmentMinutes;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

#subscribe-dialog {
  //   padding: 50px 0 !important;
  background-color: transparent;
  .right-col {
    // height: 630px;
    padding: 100px 0;
    border-top: 5px solid #a53860;
    border-radius: 0 0 0 60px;
    padding: 1rem 3rem;
    background-color: #fff;
    margin-bottom: 10px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;

    h2 {
      font-size: 1.5rem !important;
    }

    #time {
      position: relative;
      .divider-time {
        position: absolute;
        right: 49%;
        top: 22%;
        font-weight: bold;
        font-size: 2rem;
        color: $second-color;
      }
    }

    .curve-btn {
      width: 100px !important;
    }
  }
  .left-col {
    // height: 630px;
    border-bottom: 5px solid #fff;
    border-radius: 0 70px 0 0;
    padding: 1rem 2rem;
    background-color: #2d3533;
    margin-top: 10px;

    .slogan {
      position: absolute;
      top: 40%;
      right: 10%;
      font-size: 1.5rem;
      font-weight: bold;
      .slogan1 {
        // font-size: 2rem;
        margin-bottom: 3rem;
      }
      .slogan2 {
        // font-size: 2rem;
      }
    }
  }
}
</style>
