<template>
  <div>
    <pageHeader />
    <div id="bookings">
      <v-container fluid>
        <v-row>
          <v-col class="py-0" cols="12" sm="4" md="6">
            <div class="head">
              <h2 class="main-title mb-3">طلبات الحجز</h2>
            </div>
          </v-col>
          <v-col class="py-0" cols="12" sm="8" md="6">
            <v-row class="align-baseline">
              <v-col class="text-center py-0" cols="12" sm="6">
                <v-select
                  v-model="selectedBranch"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  attach
                  label="الفرع"
                  @change="getBookingsList()"
                  class="curve-input"
                ></v-select>
              </v-col>
              <v-col class="text-center py-0" cols="12" sm="6">
                <ul class="unstyled-list types-list">
                  <li
                    v-for="(type, index) in types"
                    :key="index"
                    class="d-inline-block type-item"
                    :class="selectedType == type.value ? 'active' : ''"
                    @click="selectType(type.value)"
                  >
                    {{ type.arabic_name }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-5 py-sm-0 head">
            <p class="d-flex align-center">
              <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
              <span class="line"></span>
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-simple-table
              class="orders"
              v-if="reservations && reservations.length > 0"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center user-main-color">اسم العميل</th>
                    <th class="text-center user-main-color">رقم الجوال</th>
                    <th class="text-center user-main-color">الجلسات</th>
                    <th class="text-center user-main-color">الفرع</th>
                    <th class="text-center user-main-color">تاريخ الطلب</th>
                    <th class="text-center user-main-color">وقت الطلب</th>
                    <th class="text-center user-main-color">مكان الخدمة</th>
                    <th class="text-center user-main-color"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in reservations"
                    :key="index"
                    class="text-center"
                  >
                    <td>{{ item.client_name }}</td>
                    <td style="direction: ltr">{{ item.client_mobile }}</td>
                    <td>
                      <span
                        v-for="(session, index) in item.sessions"
                        :key="index"
                      >
                        {{ session }}
                        <span v-if="index < item.sessions.length - 1"> - </span>
                      </span>
                    </td>
                    <td>{{ item.branch }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.time }}</td>
                    <td>
                      <v-btn
                        v-if="item.locations == 'home'"
                        @click="openClientDialog(index)"
                        icon
                      >
                        <v-icon color="#a53860" v-if="item.locations == 'home'"
                          >mdi-home</v-icon
                        >
                      </v-btn>

                      <span v-else>بالصالون</span>

                      <clientLocation
                        :dialog="dialogs[index]"
                        v-if="dialogs[index]"
                        :lat="item.lat"
                        :lng="item.lng"
                        :formatted_address="item.formatted_address"
                        @closeDialog="closeClientDialog(index)"
                      />
                    </td>

                    <td>
                      <div class="d-flex">
                        <v-btn
                          icon
                          @click.prevent="
                            openConfirmDialog(
                              item.id,
                              'approved',
                              item.client_name
                            )
                          "
                        >
                          <v-icon color="#a53860"
                            >mdi-check-circle-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          icon
                          @click.prevent="
                            openConfirmDialog(
                              item.id,
                              'declined',
                              item.client_name
                            )
                          "
                        >
                          <v-icon color="#a53860"
                            >mdi-close-circle-outline</v-icon
                          >
                        </v-btn>
                        <v-btn icon @click="openRescheduleDialog(index)">
                          <v-icon color="#a53860">mdi-pencil</v-icon>
                        </v-btn>

                        <rescheduleDialog
                          :dialog="rescheduleDialogs[index]"
                          :slug="item.slug"
                          :appointmentDate="item.date_2"
                          :appointmentHours="item.hours"
                          :appointmentMinutes="item.minutes"
                          :reserve_id="item.id"
                          v-if="rescheduleDialogs[index]"
                          @closeDialog="closeRescheduleDialog(index)"
                          @updateData="getBookingsList"
                          @showSnackbar="showSnackbar"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <p
              class="text-center"
              v-if="
                reservations &&
                reservations.length == 0 &&
                loaded &&
                plan.can_show == true
              "
            >
              لا يوجد حجوزات
            </p>

            <p
              class="subscribe-msg mr-6 mt-4"
              v-if="plan && plan.can_show == false"
            >
              <v-icon large color="#a53860">mdi-alert-circle-outline</v-icon>
              {{ plan.message }}
              <router-link to="/pricingList" style="color: #a53860"
                >انقر هنا</router-link
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <snackbarComp
      @closeSnack="closeSnack"
      :snackbar="snackbar"
      :color="color"
      :text="text"
    />

    <confirmDialog
      v-if="confirmDialog"
      :confirmType="confirmType"
      :dialog="confirmDialog"
      :text="dialogText"
      @confirm="updateStatus"
      @closeConfirmDialog="closeConfirmDialog"
    />
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";
import snackbarComp from "@/components/snackbarComp.vue";
// import locationDialog from "@/components/locationDialog.vue";
import confirmDialog from "@/components/confirmDialog.vue";
import clientLocation from "@/components/salon/clientLocation.vue";
import rescheduleDialog from "@/components/salon/rescheduleDialog.vue";

import { mapGetters } from "vuex";
export default {
  name: "bookingsRequests",
  components: {
    pageHeader,
    snackbarComp,
    confirmDialog,
    clientLocation,
    rescheduleDialog,
  },
  data() {
    return {
      confirmDialog: false,
      dialogText: "",
      confirmType: "",
      selectedId: "",
      loaded: false,
      reservations: [],
      plan: {},
      dialogs: [],
      rescheduleDialogs: [],
      selectedType: "",
      types: [
        { arabic_name: "بالصالون", value: "salon" },
        { arabic_name: "بالمنزل", value: "home" },
      ],
      branches: [],
      selectedBranch: "",
      snackbar: false,
      color: "#5cb85c",
      text: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    selectType(type) {
      this.selectedType = type;
      this.getBookingsList();
    },
    showSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
    openClientDialog(index) {
      this.$set(this.dialogs, index, true);
    },
    closeClientDialog(index) {
      this.$set(this.dialogs, index, false);
    },
    openRescheduleDialog(index) {
      this.$set(this.rescheduleDialogs, index, true);
    },
    closeRescheduleDialog(index) {
      this.$set(this.rescheduleDialogs, index, false);
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.selectedId = "";
    },
    closeSnack() {
      this.snackbar = false;
    },
    openConfirmDialog(request_id, type, client_name) {
      this.confirmDialog = true;
      this.confirmType = type;
      this.selectedId = request_id;
      if (this.confirmType == "approved") {
        this.dialogText = "هل أنت متأكد من تأكيد طلب " + client_name + " ؟ ";
      } else if (this.confirmType == "declined") {
        this.dialogText = "هل أنت متأكد من إلغاء طلب " + client_name + " ؟ ";
      }
    },
    updateStatus(status) {
      this.$http
        .post(
          this.base_url +
            "/salon/reservations/status/" +
            this.selectedId +
            "?status=" +
            status,
          this.branchObj,
          {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            if (status == "approved") {
              this.text = "تم تأكيد الطلب";
              this.snackbar = true;
            } else if (status == "declined") {
              this.snackbar = true;
              this.text = "تم رفض الطلب";
            }
            this.getBookingsList();
            this.selectedId = "";
            this.confirmDialog = false;
          }
        });
    },

    getBranches() {
      this.$http
        .get(this.base_url + "/salon/getSalonBranches", {
          headers: {
            locale: localStorage.locale,
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.branches = response.data.data;
        });
    },
    getBookingsList() {
      this.loaded = false;
      this.dialogs = [];
      this.rescheduleDialogs = [];
      this.$http
        .get(
          this.base_url +
            "/salon/reservations/requestsForWeb?branch_id=" +
            this.selectedBranch +
            "&type=" +
            this.selectedType,
          {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.loaded = true;
          this.reservations = response.data.data.reservations;
          this.plan = response.data.data.plan;
          let msg = this.plan.message;

          msg = msg.replace("انقر هنا", "");
          this.plan.message = msg;

          for (let i = 0; i < this.reservations.length; i++) {
            this.dialogs.push(false);
            this.rescheduleDialogs.push(false);
          }
        });
    },
  },
  mounted() {
    this.getBookingsList();
    this.getBranches();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/_variables.scss";

#bookings {
  margin: 50px 0;
  .head {
    margin: 0 80px;
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 94%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }

  .types-list {
    .type-item {
      color: #b7b7b7;
      padding: 6px 15px;
      border: 1px solid;
      cursor: pointer;
    }
    .type-item:nth-child(1) {
      border-radius: 0 10px 10px 0;
    }
    .type-item:nth-child(3) {
      border-radius: 10px 0 0 10px;
    }
    .type-item.active {
      background-color: $second-color;
      color: #ffffff !important;
    }
  }

  .orders {
    th.text-center {
      border-bottom: 1px solid $user-main-color;
      font-size: 1.2rem !important;
    }

    td {
      font-size: 1.2rem !important;
      color: #4a4949 !important;
      padding-top: 16px !important;
      padding-bottom: 16px;

      a {
        text-decoration: unset !important;
      }
    }
  }

  .subscribe-msg {
    color: $second-color !important;
  }
}

@media (max-width: 600px) {
  #bookings {
    margin: 50px 0;
    .head {
      margin: 0 12px;
    }
  }
}

@media (max-width: 960px) {
  #bookings {
    .head {
      margin: 0 12px;
    }
  }
}
</style>
